:root {
  --primary: #ec353e;
  --secondary: #19323c;
}

body {
  background-color: var(--secondary);
  text-align: center;
  color: white;
  margin: auto;
  max-width: 1200px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.geolocation {
  background-color: rgba(0, 0, 0, 0.2);
  margin: auto;
  max-width: 300px;
  border-radius: 5px;
}

button {
  background-color: #ec353e;
  padding: 5px;
  border-radius: 4px;
}

.locationsList {
  padding-top: 20px;
}

nav {
  display: flex;
  gap: 16px;
  justify-content: end;
  width: 1200px;
  margin: 0 auto;
}

nav h1 {
  margin-right: auto;
  outline: #bbb;
  color: var(--primary);
  font-size: 30px;
  border-bottom: 3px solid;
  -webkit-text-stroke: 1px black;
}

nav a {
  text-decoration: none;
  padding: 6px;
  border-radius: 4px;
  margin: auto 0;
  color: white;
}

nav a.active {
  border-bottom: 2px solid #ec353e;
}

.location {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 1em;
  margin: 1em;
}

.location > p:last-child {
  font-size: 0.8em;
  color: #bbb;
}

.weather {
  padding: 1em;
}

.leaflet-container {
  margin: auto;
  margin-top: 5em;
  width: 80%;
  height: 720px;
}
